import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'

import app from '../main'
import menu from './modules/menu'
import { setCurrentLanguage } from '../utils'
import router from '../router'
import { adminRoot } from '../constants/config';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        usuario: null
    },
    mutations: {
        changeLang(state, payload) {
            app.$i18n.locale = payload
            setCurrentLanguage(payload);
        },
        setToken(state, token){
            state.token = token;
        },
        setUser(state, user){
            state.usuario = user;
        }
    },
    actions: {
        setLang({ commit }, payload) {
            commit('changeLang', payload)
        },
        saveToken({ commit }, token){
            commit("setToken", token)
            commit("setUser", decode(token))
            localStorage.setItem("token", token)
        },
        autoLogin({ commit }){
            let token = localStorage.getItem("token")
            if(token){
                commit("setToken", token)
                commit("setUser", decode(token))

                router.push(adminRoot)
            }
        },
        exit({ commit }){
            commit("setToken", null)
            commit("setUser", null)
            localStorage.removeItem("token")
            router.push('/')
        }
    },
    modules: {
        menu
    }
})
