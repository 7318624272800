import Vue from "vue";
import VueRouter from "vue-router";
import { adminRoot , parseJwt} from "./constants/config";
import store from './store';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import("./views/auth/Login"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: adminRoot,
        component: () => import("./views/app"),
        redirect: `${adminRoot}/dashboard`,
        meta: {
          loginRequired: true
        },
        /*
        define with Authorization :
        meta: { loginRequired: true, roles: [UserRole.Admin, UserRole.Editor] },
        */
        children:[
            {
                path: "dashboard",
                component: () => import("./views/app/panel-control"),
                redirect: `${adminRoot}/dashboard`,
                children: [
                    {
                        path: '/',
                        component: () => import('./views/app/panel-control/Dashboard'),
                        meta: { roles: ['Administrador', 'Operador','Unidad Operativa'] },
                      // meta: { roles: [UserRole.Admin, UserRole.Editor] },

                    }
                ]
            },
          {
            path: "monitoring/:id",
            name: 'monitoring',
            component: () => import("./views/app/monitoring"),
            meta: { roles: ['Administrador', 'Operador'] },
          },
          {
            path: "monitoringNOV/:id",
            name: 'monitoringNOV',
            component: () => import("./views/app/monitoringNOV"),
            meta: { roles: ['Administrador', 'Operador'] },
          },
          {
            path: "operational-security",
            component: () => import("./views/app/operational-security"),
            redirect: `${adminRoot}/operational-security/cctv`,
            meta: { roles: ['Administrador', 'Operador','Unidad Operativa'] },
            children: [
              {
                path: "cctv",
                component: () => import("./views/app/operational-security/CCTV"),
              },
              {
                path: "voceo",
                component: () => import("./views/app/operational-security/Voceo"),
              },
              {
                path: "access-control",
                component: () => import("./views/app/operational-security/AccessControl"),
              }
            ]
          },
          {
            path: "physical-security",
            component: () => import("./views/app/physical-security"),
            redirect: `${adminRoot}/physical-security/intruder-detector`,
            meta: { roles: ['Administrador', 'Operador'] },
            children: [
              {
                path: "intruder-detector",
                component: () => import("./views/app/physical-security/IntruderDetector"),
              },
              {
                path: "gps",
                component: () => import("./views/app/physical-security/GPS"),
              }
            ]
          },
          {
            path: "remote-support",
            component: () => import("./views/app/remote-support"),
            redirect: `${adminRoot}/remote-support/users`,
            meta: { roles: ['Administrador'] },
            children: [

              /* ***************************** Users ***************************** */
              {
                path: "users",
                component: () => import("./views/app/remote-support/users/Users"),
              },
              {
                path: "users/create-curriculum-vitae",
                component: () => import("./views/app/remote-support/users/Create"),
                name: 'create-curriculum-vitae',
              },
              {
                path: "users/profile/:id",
                component: () => import("./views/app/remote-support/users/Profile"),
                name: 'profile',
              },
              {
                path: "users/edit-curriculum-vitae/:id",
                component: () => import("./views/app/remote-support/users/Edit"),
                name: 'edit-curriculum-vitae',
              },
              {
                path: "users/account/:id",
                component: () => import("./views/app/remote-support/users/Account"),
                name: 'account',
              },
              /* ***************************************************************** */

              /* ***************************** Sites ***************************** */
              {
                path: "sites",
                component: () => import("./views/app/remote-support/sites/Sites"),
              },
              {
                path: "sites/create-site",
                component: () => import("./views/app/remote-support/sites/Create"),
                name: 'create-site',
              },
              {
                path: "sites/edit-site/:id",
                component: () => import("./views/app/remote-support/sites/Edit"),
                name: 'edit-site',
              },
              /* ***************************************************************** */

              /* ***************************** Equipos ***************************** */
              {
                path: "equipments",
                component: () => import("./views/app/remote-support/equipments/Equipment"),
              },
              {
                path: "equipments/create-equipment",
                component: () => import("./views/app/remote-support/equipments/Create"),
                name: 'create-equipment',
              },
              {
                path: "equipments/edit-equipment/:id",
                component: () => import("./views/app/remote-support/equipments/Edit"),
                name: 'edit-equipment',
              },

              /* ***************************************************************** */

              /* ***************************** Variables ***************************** */
              {
                path: "variables",
                component: () => import("./views/app/remote-support/variables/Variables"),
              },
              /* ***************************************************************** */

              /* ***************************** Dispositivos ***************************** */
              {
                path: "devices",
                component: () => import("./views/app/remote-support/devices/Device"),
              },
              {
                path: "devices/create-device/:id",
                component: () => import("./views/app/remote-support/devices/Create"),
                name: 'create-device',
              },
              {
                path: "devices/edit-device/:id",
                component: () => import("./views/app/remote-support/devices/Edit"),
                name: 'edit-device',
              },
              {
                path: "devices/view-device/:id",
                component: () => import("./views/app/remote-support/devices/View"),
                name: 'view-device',
              },
              /* ***************************************************************** */

              /* ***************************** Botones de Panico ***************************** */
              {
                path: "panic-buttons",
                component: () => import("./views/app/remote-support/panic-buttons/PanicButtons"),
              },
              /* ***************************** Actividades ***************************** */
              {
                path: "activities",
                component: () => import("./views/app/remote-support/activities/activities"),
              },
              {
                path: "maintenance",
                component: () => import("./views/app/remote-support/maintenance/maintenance"),
              },
              {
                path: "ast",
                component: () => import("./views/app/remote-support/ast/ast"),
              },
              {
                path: "vehicles",
                component: () => import("./views/app/remote-support/vehicles/Vehicle"),
              },
              {
                path: "alerts/:id",
                component: () => import("./views/app/alerts/Alerts"),
              },
              {
                path: "alerts",
                component: () => import("./views/app/alerts/Alerts"),
              },

              /* ***************************************************************** */

              /* ***************************** Calendario ***************************** */
              /*{
                path: "calendar",
                component: () => import("./views/app/remote-support/calendar/Calendar"),
              }*/
              /* ***************************************************************** */
            ]
          },
          {
            path: "remote-support2",
            component: () => import("./views/app/remote-support"),
            redirect: `${adminRoot}/remote-support/users`,
            meta: { roles: ['Administrador','Operador'] },
            children: [
              {
                path: "panic-buttons",
                component: () => import("./views/app/remote-support/panic-buttons/PanicButtons"),
              },
              /* ***************************** Actividades ***************************** */
              {
                path: "activities",
                component: () => import("./views/app/remote-support/activities/activities"),
              },
              {
                path: "maintenance",
                component: () => import("./views/app/remote-support/maintenance/maintenance"),
              },
              {
                path: "alerts/:id",
                component: () => import("./views/app/alerts/Alerts"),
              },
              {
                path: "alerts",
                component: () => import("./views/app/alerts/Alerts"),
              },
              {
                path: "users/profile/:id",
                component: () => import("./views/app/remote-support/users/Profile"),
                name: 'profile',
              },
              {
                path: "equipments/view-equipment/:id",
                component: () => import("./views/app/remote-support/equipments/SiteView"),
                name: 'view-equipment',
              },
            ]
          },

          {
            path: "chat",
            component: () => import("./views/app/chat"),
            redirect: `${adminRoot}/chat`,
            meta: { roles: ['Administrador','Operador'] },
            children: [
              {
                path: "chat/:id/:id_chat?",
                component: () => import("./views/app/chat/Chat"),
                name: 'chat'
              },
            ]
          },
          /*{
            path: "alerts",
            component: () => import("./views/app/alerts"),
            redirect: `${adminRoot}/alerts`,
            children: [
              {
                path: "alerts/:id_alert?",
                component: () => import("./views/app/alerts/Alerts"),
                name: 'alerts'
              },
            ]
          }*/

            /*{
                path: "monitoring",
                component: () => import("./views/app/monitoring"),
                redirect: `${adminRoot}/monitoring/pm-1603`,
                children: [
                    {
                        path: "pm-1603",
                        component: () => import("./views/app/monitoring/pm-1603"),
                        redirect: `${adminRoot}/monitoring/pm-1603/surface-pm-1603`,
                        children: [
                            {
                                path: "surface-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/Surface")
                            },
                            {
                                path: "generators-2",
                                component: () => import("./views/app/monitoring/pm-1603/Generators-2")
                            },
                            {
                                path: "general",
                                component: () => import("./views/app/monitoring/pm-1603/General")
                            },
                            {
                                path: "top-drive-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/TopDrive")
                            },
                            {
                                path: "bombs-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/Bombs")
                            },
                            {
                                path: "winch-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/Winch")
                            },
                            {
                                path: "power-distributor-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/PowerDistributor")
                            },
                            {
                                path: "generators-1-2-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/Generators-1-2")
                            },
                            {
                                path: "generators-3-4-pm-1603",
                                component: () => import("./views/app/monitoring/pm-1603/Generators-3-4")
                            },
                        ]
                    },
                    {
                        path: "pm-1503",
                        component: () => import("./views/app/monitoring/pm-1503"),
                        redirect: `${adminRoot}/monitoring/pm-1503/surface-pm-1503`,
                        children: [
                            {
                                path: "surface-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/Surface")
                            },
                            {
                                path: "top-drive-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/TopDrive")
                            },
                            {
                                path: "bombs-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/Bombs")
                            },
                            {
                                path: "winch-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/Winch")
                            },
                            {
                                path: "power-distributor-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/PowerDistributor")
                            },
                            {
                                path: "generators-1-2-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/Generators-1-2")
                            },
                            {
                                path: "generators-3-4-pm-1503",
                                component: () => import("./views/app/monitoring/pm-1503/Generators-3-4")
                            },
                        ]
                    }
                ]
            },
            {
                path: "operational-security",
                component: () => import("./views/app/operational-security"),
                redirect: `${adminRoot}/operational-security/cctv`,
                children: [
                    {
                        path: "cctv",
                        component: () => import("./views/app/operational-security/CCTV"),
                    },
                    {
                        path: "voceo",
                        component: () => import("./views/app/operational-security/Voceo"),
                    },
                    {
                        path: "access-control",
                        component: () => import("./views/app/operational-security/AccessControl"),
                    }
                ]
            },
            {
                path: "physical-security",
                component: () => import("./views/app/physical-security"),
                redirect: `${adminRoot}/physical-security/intruder-detector`,
                children: [
                    {
                        path: "intruder-detector",
                        component: () => import("./views/app/physical-security/IntruderDetector"),
                    },
                    {
                        path: "gps",
                        component: () => import("./views/app/physical-security/GPS"),
                    }
                ]
            },
            {
                path: "remote-support",
                component: () => import("./views/app/remote-support"),
                redirect: `${adminRoot}/remote-support/users`,
                children: [

                    {
                        path: "users",
                        component: () => import("./views/app/remote-support/users/Users"),
                    },
                    {
                        path: "users/create-curriculum-vitae",
                        component: () => import("./views/app/remote-support/users/Create"),
                        name: 'create-curriculum-vitae',
                    },
                    {
                        path: "users/profile/:id",
                        component: () => import("./views/app/remote-support/users/Profile"),
                        name: 'profile',
                    },
                    {
                        path: "users/edit-curriculum-vitae/:id",
                        component: () => import("./views/app/remote-support/users/Edit"),
                        name: 'edit-curriculum-vitae',
                    },
                    {
                        path: "users/account/:id",
                        component: () => import("./views/app/remote-support/users/Account"),
                        name: 'account',
                    },
                    {
                        path: "sites",
                        component: () => import("./views/app/remote-support/sites/Sites"),
                    },
                    {
                        path: "sites/create-site",
                        component: () => import("./views/app/remote-support/sites/Create"),
                        name: 'create-site',
                    },
                    {
                        path: "sites/edit-site",
                        component: () => import("./views/app/remote-support/sites/Edit"),
                        name: 'edit-site',
                    },
                    {
                        path: "variables",
                        component: () => import("./views/app/remote-support/Variables"),
                    },
                    {
                        path: "equipment",
                        component: () => import("./views/app/remote-support/Equipment"),
                    },
                    {
                        path: "panic-buttons",
                        component: () => import("./views/app/remote-support/PanicButtons"),
                    },
                    {
                        path: "calendar",
                        component: () => import("./views/app/remote-support/Calendar"),
                    }
                ]
            },
            {
                path: "chat",
                component: () => import("./views/app/chat"),
                redirect: `${adminRoot}/chat`,
                children: [

                    {
                        path: "chat/:id",
                        component: () => import("./views/app/chat/Chat"),
                        name: 'chat'
                    },
                ]
            }*/
        ]
    },
  {
    path: "/unauthorized",
    component: () => import(/* webpackChunkName: "error" */ "./views/auth/Unauthorized")
  },
];

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
    mode: "history",
});

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(record => record.meta.loginRequired)) {
    const user = store.state.usuario;
    if (user) {
      const jwtPayload = parseJwt(store.state.token);
      if (jwtPayload.exp > Date.now()/1000) {
        const roleArrayHierarchic = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);
        if (roleArrayHierarchic.every(x => x.includes(user.rol))) {
          next();
        }else{
          next('/unauthorized')
        }
      }
      else{
        store.dispatch("exit");
      }
    }else {
      next('/')
    }
  }else {
    next()
  }

})

export default router;
