<template>
    <div class="row">
        <div class="col-md-12">
            <div class="step-wrapper" :class="stepWrapperClass">
                <button type="button" class="btn btn-primary" @click="lastStep" :disabled="firststep">
                    <span class="fa fa-step-backward"></span>&nbsp;Anterior
                </button>
                <div class="float-right form-group">
                    <button type="button" class="btn btn-primary" @click="nextStep" :disabled="laststep" v-if="!laststep">
                        Siguiente&nbsp;<span class="fa fa-step-forward"></span>
                    </button>
                    <div v-if="laststep">
                        <button type="submit" :class="guardar ? 'btn btn-success' : 'btn btn-dark'" :disabled="disabledButton" v-text="guardar ? guardar: actualizar"></button>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
    export default {

        props: ["step", "stepcount", "currentstep", "guardar", "actualizar", "disabledButton"],

        computed: {
            active() {
                return this.step.id == this.currentstep;
            },

            firststep() {
                return this.currentstep == 1;
            },

            laststep() {
                return this.currentstep == this.stepcount;
            },

            stepWrapperClass() {
                return {
                    active: this.active
                };
            }
        },

        methods: {
            nextStep() {
                this.$emit("step-change", this.currentstep + 1);
            },

            lastStep() {
                this.$emit("step-change", this.currentstep - 1);
            },
        }
    }
</script>