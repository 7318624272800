import Vue from 'vue';
import App from './App';
import { serverIP,port,requestType,requestWSType } from './constants/config';

import VueNativeSock from 'vue-native-websocket';

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue';
//sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// Router & Store add
import router from './router';
import store from './store';
// Multi Language Add
import en from './locales/en.json';
import es from './locales/es.json';
import VueI18n from 'vue-i18n';
// Notification Component Add
import Notifications from './components/Common/Notification';
// Breadcrumb Component Add
import Breadcrumb from './components/Common/Breadcrumb';
// RefreshButton Component Add
import RefreshButton from './components/Common/RefreshButton';
// Colxx Component Add
import Colxx from './components/Common/Colxx';
// Perfect Scrollbar Add
import vuePerfectScrollbar from 'vue-perfect-scrollbar';

import { getCurrentLanguage } from './utils';
import axios from 'axios';
import FullCalendar from 'vue-full-calendar'


import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import myUpload from 'vue-image-crop-upload';
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap';
import Datepicker from "vuejs-datepicker";

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import InputColorPicker from "vue-native-color-picker";

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import GmapPolyline from 'vue2-google-maps/dist/components/polyline';
import VueFusionCharts from 'vue-fusioncharts';
import VueKonva from 'vue-konva';
Vue.use(VueKonva);

import VueFriendlyIframe from 'vue-friendly-iframe';
Vue.use(VueFriendlyIframe);

import lineClamp from 'vue-line-clamp'
Vue.use(lineClamp, {})

import resize from "vue-element-resize-detector";
Vue.use(resize);


// import FusionCharts modules and resolve dependency
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import {library} from '@fortawesome/fontawesome-svg-core';
import { faCheck,faTrashAlt,faPlus,faArrowDown,faArrowUp,faFileWord,faFileExcel,faFilePdf,faFilePowerpoint,faFileImage,faFile,faFileArchive,faFileDownload,faEye,faCog,faEdit,faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueNumberInput from '@chenfengyuan/vue-number-input';
//import VueXlsx from 'vue-js-xlsx'
//Vue.use(VueXlsx);

import VueSvgGauge from 'vue-svg-gauge'
Vue.use(VueSvgGauge)
import VueMoment from 'vue-moment'
Vue.use(VueMoment);
Vue.component('vue-number-input', VueNumberInput);

import { Network } from "vue-vis-network";

Vue.component('network', Network);

library.add(faCheck,faTrashAlt,faPlus,faArrowDown,faArrowUp,faFileWord,faFileExcel,faFilePdf,faFilePowerpoint,faFileImage,faFile,faFileArchive,faFileDownload,faEye,faCog,faEdit,faArrowLeft);

Vue.use(VueFusionCharts, FusionCharts, Charts);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('datepicker', Datepicker);
Vue.component('timepicker', VueTimepicker);

Vue.component('v-select', vSelect);

Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);

Vue.component('my-upload', myUpload);

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD7ul3cxpIwdBHrQ5I45YYakX5Fm6VzcNk',
    libraries: ["places", "visualization", "geometry", "drawing"]
  },

})
/*
Vue.component('gmap-map', VueGoogleMaps.Map);
Vue.component('gmap-marker', VueGoogleMaps.Marker);
Vue.component('gmap-info-window', VueGoogleMaps.InfoWindow);
Vue.component('gmap-cluster', GmapCluster);
Vue.component('gmap-polyline', GmapPolyline);*/

Vue.use(InputColorPicker);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(VueI18n);
const messages = { en: en, es: es };
const locale = getCurrentLanguage();
const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages
});

Vue.use(Notifications);

Vue.use(FullCalendar);

Vue.use(VueNativeSock, `${requestWSType}://${serverIP}:${port}/`, {
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
  //  reconnectionAttempts: 20, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 1000, // (Number) how long to initially wait before attempting a new (1000)
    protocol: 'echo-protocol',
});

Vue.component('piaf-breadcrumb', Breadcrumb);
Vue.component('b-refresh-button', RefreshButton);
Vue.component('b-colxx', Colxx);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);

Vue.component('step-navigation-step', require('./components/Step/step-navigation-step.vue').default);
Vue.component('step-navigation', require('./components/Step/step-navigation.vue').default);
Vue.component('step', require('./components/Step/step.vue').default);

Vue.config.productionTip = false;

axios.defaults.baseURL = `${requestType}://${serverIP}:${port}/`;//:${port}/`;

/* axios.interceptors.response.use(undefined, function(error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            //store.dispatch("LogOut");
            return router.push("/login");
        }
    }
}); */

export default new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
