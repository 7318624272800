<template>
    <div>
        <div v-if="isLogged" class="h-100">
            <router-view />
            <color-switcher />
        </div>
        <logged v-if="!isLogged"></logged>
    </div>
</template>

<script>
import ColorSwitcher from "./components/Common/ColorSwitcher";

import { getDirection } from "./utils";

import Logged from './views/auth/Logged.vue';

export default {
  components: {
        "color-switcher": ColorSwitcher,
        Logged
  },
  beforeMount() {
        const direction = getDirection();
        if (direction.isRtl) {
            document.body.classList.add("rtl");
            document.dir = "rtl";
            document.body.classList.remove("ltr");
        } else {
            document.body.classList.add("ltr");
            document.dir = "ltr";
            document.body.classList.remove("rtl");
        }
  },
  computed: {
        isLogged(){
            return this.$store.state.usuario;
        }
  },
  created(){
        this.$store.dispatch("autoLogin");
  },
    
};
</script>
