export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`
/*
export const serverIP = 'www.engineerix.com.mx';
//export const serverIP = '72.14.185.242';
export const serverIPn = '72.14.185.242';
export const port = '';
export const requestType = 'https';
export const requestWSType = 'wss';*/

let dev = false;
export const serverIP =(dev? 'localhost':window.location.hostname) //'10.168.10.252', 'www.engineerix.com.mx';
export const serverVideo =(dev? 'localhost':window.location.hostname) //'10.168.10.252', 'www.engineerix.com.mx';
export const serverIPn = (dev? 'localhost':window.location.hostname) //'10.168.10.252';
export const port =  (dev? '3015':window.location.port)//3015';
export const portVideo =  (dev? '3015':window.location.port)//3015';
export const requestType = (dev? 'http':window.location.protocol.replace(':', ''));
export const requestWSType = (dev? 'ws':(window.location.protocol.replace(':', '') === 'http'? 'ws':'wss'));

/*
export const serverIP = '192.168.1.96';
export const serverIPn = '192.168.1.96';
export const port = '3015';
export const requestType = 'http';
export const requestWSType = 'ws';*/

export const portButtonsPanic = '3012';
export const portGps = '3008';

export const apiUrlCarreras = `${requestType}://${serverIPn}:${port}/js/api/carreras.json`;

export const apiUrlDespachoGuardia = `${requestType}://${serverIPn}:${port}/js/api/despacho_guardia.json`;

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'es'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
]

export const isAuthGuardActive = false;
export const themeRadiusStorageKey = 'theme_radius'
export const themeSelectedColorStorageKey = 'theme_selected_color'
export const defaultColor = 'light.blueolympic'
export const colors = ['bluenavy', 'blueyale', 'blueolympic', 'greenmoss', 'greenlime', 'purplemonster', 'orangecarrot', 'redruby', 'yellowgranola', 'greysteel']

export const  parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
